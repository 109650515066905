import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';
import { useEffectOnce, useTitle } from 'react-use';

import { GamePackUtils } from '../components/Game/GamePack/utils';
import { OTPUtils } from '../components/OneTimePurchase';
import { CheckoutChoosePurchaseOption } from '../components/OneTimePurchase/OTPCheckoutChoosePurchaseOption';
import { useTimezone } from '../hooks/useTimezone';
import { apiService } from '../services/api-service';
import { makeTitle } from '../utils/common';
import { useCheckoutLoaderData } from './checkout.client';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);
  const headcount = OTPUtils.ParseHeadcount(url.searchParams.get('headcount'));

  return {
    headcount,
  };
}

export function Component() {
  const { pack, product } = useCheckoutLoaderData();
  const { headcount } = useLoaderData<typeof clientLoader>();
  const timezone = useTimezone();
  useTitle(makeTitle(`Choose a Purchase Option | Purchase ${pack.name}`));

  const price = GamePackUtils.FindOneTimePrice(pack, headcount);

  // create OTP intent, so we can track and recover the checkout
  useEffectOnce(() => {
    if (!price) return;

    apiService.gamePack.createOTPIntent(pack.id, {
      priceId: price.id,
      path: window.location.pathname,
      queries: window.location.search,
      timezone,
    });
  });

  return (
    <CheckoutChoosePurchaseOption
      pack={pack}
      product={product}
      headcount={headcount}
    />
  );
}
