import { Link } from '@remix-run/react';
import { useEffectOnce } from 'react-use';
import { $path } from 'remix-routes';

import {
  type DtoGamePack,
  type DtoProduct,
} from '@lp-lib/api-service-client/public';
import { MediaFormatVersion } from '@lp-lib/media';

import { useOneTimePurchaseAnalytics } from '../../analytics/oneTimePurchase';
import config from '../../config';
import { fromDTOGamePack } from '../../utils/api-dto';
import { getStaticAssetPath } from '../../utils/assets';
import { formatCurrency } from '../../utils/currency';
import { Tooltip } from '../common/Tooltip';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { GamePackCoverImage } from '../Game/GamePackCoverPres';
import { CloseIcon } from '../icons/CloseIcon';
import { PlayIcon } from '../icons/PlayIcon';
import { QuestionMarkIcon } from '../icons/QuestionMarkIcon';
import { ProductUtils } from '../Product/utils';
import { OTPCheckoutLayout } from './OTPCheckoutLayout';

function SpotifySocialProof() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const analytics = useOneTimePurchaseAnalytics();

  const handleViewVideo = () => {
    analytics.trackEventPurchaseTestimonialVideoViewed();

    triggerModal({
      kind: 'custom',
      element: (p) => (
        <ModalWrapper
          containerClassName='w-3/4 max-w-[#1280]'
          innerClassName='rounded-xl'
          borderStyle='gray'
          onClose={p.internalOnConfirm}
        >
          <div className='relative w-full group'>
            <div className='absolute z-10 right-2.5 top-2.5 group-hover:opacity-100 opacity-0'>
              <button
                type='button'
                className='w-7 h-7 flex justify-center items-center rounded-full bg-black bg-opacity-50 hover:bg-opacity-75'
                onClick={p.internalOnConfirm}
              >
                <CloseIcon className='w-4 h-4 fill-current text-white' />
              </button>
            </div>

            <video
              src={getStaticAssetPath(
                'videos/otp/choose-purchase-option-testimonial.mp4'
              )}
              className='w-full rounded-xl'
              controls
              autoPlay
            />
          </div>
        </ModalWrapper>
      ),
    });
  };

  return (
    <div className='w-60 bg-white text-black rounded-2.5xl p-5 flex flex-col gap-3'>
      <div className='flex items-center gap-2'>
        <img
          src={getStaticAssetPath('images/onboarding/spotify.png')}
          alt=''
          className='w-19 h-5.5 object-contain'
        />
        <div className='text-icon-gray text-3xs font-medium'>Case Study</div>
      </div>
      <div className='w-full text-sms'>
        Spotify transformed how they collaborate and increased their engagement
        by 950% giving their team unlimited access.
      </div>
      <button
        type='button'
        className='text-sms font-bold text-[#383838] flex items-center gap-1'
        onClick={handleViewVideo}
      >
        <PlayIcon className='w-3.5 h-3.5 fill-current text-[#383838]' />
        Watch Testimonial Video
      </button>
    </div>
  );
}

function PurchaseOptionSubscription(props: {
  product: DtoProduct;
  pack: DtoGamePack;
  headcount: number;
}) {
  const { product, pack, headcount } = props;

  const analytics = useOneTimePurchaseAnalytics();
  const prices = ProductUtils.ActivePricesBySmallestBillingInterval(product);
  const price = ProductUtils.FindPrice(prices, headcount);

  if (!price) return null;

  const search = new URLSearchParams(window.location.search);
  search.set('product-id', product.id);
  search.set('price-id', price.id);
  search.set('headcount', headcount.toString());

  const hasUnlimitedLiveBooking = ProductUtils.HasUnlimitedLiveBooking(product);

  return (
    <div
      className='
          w-192 bg-white text-black rounded-2.5xl 
          p-5 flex flex-col items-center
        '
    >
      <div className='text-red-006 text-xl font-bold'>
        Unlimited Access for the Price of a Single Event!
      </div>
      <div className='mt-2 flex gap-1'>
        <p className='text-xl font-bold'>
          {formatCurrency(price.amount)}/
          {ProductUtils.FormatInterval(price.billingInterval)}
        </p>
        <div className='relative group flex justify-center'>
          <QuestionMarkIcon className='w-3.5 h-3.5 fill-current text-icon-gray' />
          <div className='absolute invisible group-hover:visible z-5 bottom-full -mb-1'>
            <Tooltip
              position={'top'}
              backgroundColor='black'
              borderRadius={12}
              arrowWidth={12}
              borderColor={'rgba(255, 255, 255, 0.4)'}
              borderWidth={1}
            >
              <p className=' w-62 p-3 text-3xs text-white'>
                Luna Park is exclusively available as a quarterly membership to
                support your team’s ongoing engagement.
              </p>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className='mt-1.5 text-3xs font-medium text-icon-gray'>
        Cancel Anytime
      </div>

      <div className='mt-3 w-full grid grid-cols-2 gap-2'>
        <div className='bg-black bg-opacity-5 rounded-xl px-3 py-5'>
          <div className='text-base font-bold text-center'>You'll get:</div>
          <ul className='pt-5 flex flex-col gap-3'>
            <li className='flex gap-2.5'>
              <p className='text-green-001'>✓</p>
              <div className='flex items-center gap-2'>
                <GamePackCoverImage
                  pack={fromDTOGamePack(pack)}
                  preferredFormatVersion={MediaFormatVersion.MD}
                  alt={''}
                  className='flex-none w-24 h-13 object-cover rounded'
                />
                <p className='flex-1'>
                  Anytime Access to <br /> {pack.name}
                </p>
              </div>
            </li>
            <li className='flex gap-2.5'>
              <p className='text-green-001'>✓</p>
              <p>Access to live engagement specialist</p>
            </li>

            <li className='flex gap-2.5'>
              <p className='text-green-001'>✓</p>
              <p>Shareable event highlight image</p>
            </li>
          </ul>
        </div>

        <div className='bg-black bg-opacity-5 rounded-xl px-3 py-5'>
          <div className='text-base font-bold text-center'>
            Plus so much more ...
          </div>
          <ul className='pt-5 flex flex-col gap-3'>
            <li className='flex gap-2.5'>
              <p className='text-green-001'>✓</p>
              <p>Unlimited On Demand Icebreakers, Escape Rooms, and more</p>
            </li>
            <li className='flex gap-2.5'>
              <p className='text-green-001'>✓</p>
              <p>Starts at 2+ people and 10+ minutes</p>
            </li>
            {hasUnlimitedLiveBooking && (
              <li className='flex gap-2.5'>
                <p className='text-green-001'>✓</p>
                <p>Unlimited Live Hosted Events</p>
              </li>
            )}
          </ul>
        </div>
      </div>

      <div className='mt-5 flex flex-col items-center gap-1.5'>
        <Link
          to={config.hubspot.bookDemoUrl}
          className='btn-primary w-75 h-10 flex justify-center items-center text-white font-bold'
        >
          Book a Demo 📆
        </Link>
        <div className='mt-2 text-sms'>
          Ready to Buy?{' '}
          <Link
            to={{
              pathname: $path('/onboarding/billing'),
              search: search.toString(),
            }}
            onClick={() =>
              analytics.trackEventPurchaseOptionChosen({
                type: 'subscription',
              })
            }
            className='btn text-primary underline'
          >
            Checkout Now!
          </Link>
        </div>
      </div>
    </div>
  );
}

export function CheckoutChoosePurchaseOption(props: {
  pack: DtoGamePack;
  product: DtoProduct;
  headcount: number;
}) {
  const { pack, product, headcount } = props;

  const analytics = useOneTimePurchaseAnalytics();

  useEffectOnce(() => {
    analytics.trackEventPurchasePurchaseOptionViewed();
  });

  return (
    <OTPCheckoutLayout
      pack={pack}
      progress={20}
      checkAccessible
      background={false}
      containerClassName='!w-full h-full !items-start'
    >
      <div className='flex flex-col items-center'>
        <div className='mt-10 relative'>
          <PurchaseOptionSubscription
            product={product}
            pack={pack}
            headcount={headcount}
          />

          <div className='absolute top-0 left-full ml-5'>
            <SpotifySocialProof />
          </div>
        </div>

        <div className='mt-7.5 w-140 flex flex-col gap-2'>
          <div className='text-sms text-icon-gray italic text-center'>
            Trusted by 90,000+ customers in 100+ countries
          </div>
          <div className='flex items-center justify-between'>
            <img
              src={getStaticAssetPath('images/onboarding/microsoft-v2.png')}
              alt='microsoft'
              className='w-30'
            />
            <img
              src={getStaticAssetPath('images/onboarding/stripe-v2.png')}
              alt='stripe'
              className='w-21'
            />
            <img
              src={getStaticAssetPath('images/onboarding/spotify-v2.png')}
              alt='spotify'
              className='w-25'
            />
            <img
              src={getStaticAssetPath('images/onboarding/meta-v2.png')}
              alt='meta'
              className='w-25'
            />
            <img
              src={getStaticAssetPath('images/onboarding/netflix-v3.png')}
              alt='netflix'
              className='w-22'
            />
          </div>
        </div>
      </div>
    </OTPCheckoutLayout>
  );
}
